<template>
  <div class="input-container">
    <div v-if="label" class="dropdown-title">
      {{ label }}
    </div>
    <div class="select-container">
      <select class="select" :value="value" @change="onChange" required>
        <option value="" disabled hidden selected>
          Select the most fitting...
        </option>
        <option value="Cybersecurity">Cybersecurity</option>
        <option value="Fintech">Fintech</option>
        <option value="Other">Other</option>
      </select>
      <b-button id="industryInput" style="background-color: initial; border: 0">
        <img
          class="icon"
          :src="require('@/assets/entityIcons/questionMark.svg')"
          alt="Question Mark Icon"
        />
      </b-button>
      <b-popover target="industryInput" triggers="hover" placement="top">
        <div
          style="
            background: var(--lightest-grey, #f6f6f6);
            border-radius: 8px;
            padding: 15px;
            max-width: 300px;
            color: var(--main, #092e3f);
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          <p>
            We use your industry information to tailor our analysis and provide
            insights specific to your sector.
          </p>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "SzDropdown",
  props: ["label", "value", "onChange"],
};
</script>

<style scoped>
.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--def-spacing, 8px);
  align-self: stretch;
}
.dropdown-title {
  display: flex;
  height: 11px;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;

  color: var(--main, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Large */
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 4px 4px 0 0;
}
.select {
  display: flex;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-top: var(--zero, 0px) solid var(--main, #092e3f);
  border-right: var(--zero, 0px) solid var(--main, #092e3f);
  border-bottom: 1px solid var(--main, #092e3f);
  border-left: var(--zero, 0px) solid var(--main, #092e3f);
  background: var(--lightest-grey, #f6f6f6);

  color: var(--muted-text, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.select:required:invalid {
  color: var(--normal-black, #bdbcbc);
}

.icon {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
