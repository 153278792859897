<template>
  <div class="input-container">
    <div v-if="label" class="input-title">
      {{ label }}
    </div>
    <div class="text-field-container">
      <input
        :disabled="isDisabled"
        type="text"
        :value="this.value"
        @input="updateInternalValue($event.target.value)"
        :class="getInputStyle()"
        :placeholder="placeholder"
      />
      <img
        v-if="verified"
        class="icon-checked"
        :src="require('@/assets/entityIcons/checkmark.svg')"
        alt="Checked"
      />
      <SzButton
        v-if="buttonText"
        :innerText="buttonText"
        :clickFunc="buttonFunc"
        class="input-button"
      />
      <b-button :id="id" style="background-color: initial; border: 0">
        <img
          class="icon"
          :src="require('@/assets/entityIcons/questionMark.svg')"
          alt="Question Mark Icon"
        />
      </b-button>
      <b-popover :target="id ? id : ''" triggers="hover" placement="top">
        <div
          style="
            background: var(--lightest-grey, #f6f6f6);
            border-radius: 8px;
            padding: 15px;
            max-width: 300px;
            color: var(--main, #092e3f);
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          "
        >
          <p>
            {{ tooltipText }}
            <a
              v-if="tooltipLink && tooltipLink.length > 0"
              :href="tooltipLink"
              target="_blank"
              >here.</a
            >
          </p>
        </div>
      </b-popover>
    </div>
  </div>
</template>

<script>
import SzButton from "@/components/universal/SzButton/SzButton";

export default {
  name: "SzInputField",
  components: {
    SzButton,
  },
  props: [
    "tooltipText",
    "tooltipLink",
    "id",
    "label",
    "placeholder",
    "buttonText",
    "buttonFunc",
    "verified",
    "isDisabled",
    "value",
    "updateInternalValue",
    "highlight",
  ],
  data() {
    return {
      internalValue: this.value,
    };
  },
  methods: {
    getInputStyle() {
      return this.highlight
        ? "highlighted-input"
        : this.isDisabled
        ? "text-field-disabled"
        : this.value === "123"
        ? "text-field-correct"
        : "text-field";
    },
  },
};
</script>

<style scoped>
.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--def-spacing, 8px);
  align-self: stretch;
  font-family: "Lato", sans-serif;
}
.input-title {
  display: flex;
  line-height: 11px;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;

  color: var(--main, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Large */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  /*line-height: normal;*/
}
.text-field-container {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 4px 4px 0 0;
  position: relative;
}
.text-field {
  display: flex;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  align-items: center;
  gap: var(--def-spacing, 8px);
  flex: 1 0 0;

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-top: var(--zero, 0px) solid var(--main, #092e3f);
  border-right: var(--zero, 0px) solid var(--main, #092e3f);
  border-bottom: 1px solid var(--main, #092e3f);
  border-left: var(--zero, 0px) solid var(--main, #092e3f);
  background: var(--lightest-grey, #f6f6f6);

  color: var(--normal-black, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-field::placeholder,
.text-field-correct::placeholder,
.highlighted-input::placeholder,
.text-field-disabled::placeholder {
  color: var(--normal-black, #898989);
}

.text-field-correct {
  display: flex;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-top: var(--zero, 0px) solid var(--green, #76ba3b);
  border-right: var(--zero, 0px) solid var(--green, #76ba3b);
  border-bottom: 1px solid var(--green, #76ba3b);
  border-left: var(--zero, 0px) solid var(--green, #76ba3b);
  background: var(--light-grey, #d6d6d6);

  color: var(--muted-text, #979394);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.highlighted-input {
  display: flex;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  align-items: center;
  gap: var(--def-spacing, 8px);
  flex: 1 0 0;
  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-bottom: 1px solid var(--main, #092e3f);

  box-shadow: 0px 1px 2px rgba(255, 31, 31, 0.5),
    0px 2px 4px rgba(255, 31, 31, 0.1), 0px 4px 8px rgba(255, 31, 31, 0.1),
    0px 8px 16px rgba(255, 31, 31, 0.1);
  background: var(--light-grey, #d6d6d6);

  color: var(--muted-text, #979394);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: auto;
}
.text-field-disabled {
  display: flex;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  align-items: center;
  gap: var(--def-spacing, 8px);
  flex: 1 0 0;

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-top: var(--zero, 0px) solid var(--main, #092e3f);
  border-right: var(--zero, 0px) solid var(--main, #092e3f);
  border-bottom: 1px solid var(--main, #092e3f);
  border-left: var(--zero, 0px) solid var(--main, #092e3f);
  background: var(--light-grey, #d6d6d6);

  color: var(--muted-text, #979394);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: auto;
}
.icon {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.icon-checked {
  right: 40px;
  width: 24px;
  height: 24px;
  /* position: absolute; */
  /*margin-right: 40px;*/
}
.input-button {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border: none;
  border-radius: 4px;
  background: #fff;

  color: var(--Prussian-Blue, #082e3f);

  /* Label Black */
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
</style>
