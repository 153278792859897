<template>
  <div class="container">
    <div class="frame">
      <div class="header">
        <div class="title">Welcome</div>
        <div class="subtitle">
          To initiate your Seculyze experience, we'll begin by linking your
          Microsoft Sentinel account, which is crucial for customizing our
          security solutions to fit your specific needs.
        </div>
        <div class="subtitle">
          Check out our full
          <a href="" target="_blank">Onboarding Documentation</a> for more
          details.
        </div>
      </div>
      <div class="input-credentials">
        <div class="sentinel-credentials">
          <div class="header">
            <div class="title">
              Microsoft Sentinel <br />
              Credentials
            </div>
          </div>
          <div class="input-fields">
            <SzInputField
              :id="'tenantId'"
              :tooltipText="'You can find your Tenant ID'"
              :tooltipLink="'ttt'"
              :value="tenantId"
              :updateInternalValue="updateTenantId"
              label="Tenant ID"
              placeholder="Example:  ec12e900-5e6z-4r22-bd78-879f7sfw14cg"
              icon=""
              :verified="verified"
              :isDisabled="verified || !changeTenantIdPermission"
              :highlight="isNullOrWhitespace(tenantId) && hoveredConfirm"
              button-text="Change"
              :buttonFunc="allowChangeTenantId"
            >
            </SzInputField>
            <SzInputField
              :id="'clientId'"
              :value="clientId"
              :tooltipText="'You can find your Client ID'"
              :tooltipLink="'ttt'"
              :updateInternalValue="updateClientId"
              label="Client ID"
              placeholder="Example:  ec12e900-5e6z-4r22-bd78-879f7sfw14cg"
              icon=""
              :verified="verified"
              :isDisabled="verified"
              :highlight="isNullOrWhitespace(clientId) && hoveredConfirm"
            />
            <SzInputField
              :id="'clientSecret'"
              :tooltipText="'You can find your Client Secret'"
              :tooltipLink="'ttt'"
              :value="clientSecret"
              :updateInternalValue="updateClientSecret"
              label="Client Secret"
              placeholder="Client secret"
              icon=""
              :verified="verified"
              :isDisabled="verified"
              :highlight="isNullOrWhitespace(clientSecret) && hoveredConfirm"
            />
            <SzInputField
              :id="'subscriptionId'"
              :tooltipText="'You can find your Subscription ID'"
              :tooltipLink="'ttt'"
              :value="subscriptionId"
              :updateInternalValue="updateSubscriptionId"
              label="Subscription ID"
              placeholder="Example: 66f52e12-443a-4a22-b76a-v8zz8ad7b112"
              icon=""
              :verified="verified"
              :isDisabled="verified"
              :highlight="isNullOrWhitespace(subscriptionId) && hoveredConfirm"
            />
            <SzInputField
              :id="'workspaceId'"
              :tooltipText="'You can find your Workspace ID'"
              :tooltipLink="'ttt'"
              :value="workspaceId"
              :updateInternalValue="updateWorkspaceId"
              label="Workspace ID"
              placeholder="Example: x31r6112-4311-1109-gt89-7d7186e9a1c9"
              icon=""
              :verified="verified"
              :isDisabled="verified"
              class="custom-input-style"
              :highlight="isNullOrWhitespace(workspaceId) && hoveredConfirm"
            />
            <SzInputField
              :id="'workspaceName'"
              :tooltipText="'You can find your Workspace Name'"
              :tooltipLink="'ttt'"
              :value="workspaceName"
              :updateInternalValue="updateWorkspaceName"
              label="Workspace Name"
              placeholder="Name of your Azure Workspace"
              icon=""
              :verified="verified"
              :isDisabled="verified"
              :highlight="isNullOrWhitespace(workspaceName) && hoveredConfirm"
            />
            <template v-if="loading">
              <div class="loading-blocker">
                <div id="logo-container">
                  <img
                    id="outer-logo"
                    :src="require('@/assets/entityIcons/logo_outer_ring.svg')"
                    alt="logo"
                  />
                  <img
                    id="inner-logo"
                    :src="require('@/assets/entityIcons/logo_inner_ring.svg')"
                    alt="logo"
                  />
                </div>
                <div class="text-loader">
                  <div
                    v-for="(text, index) in loadingTexts"
                    :key="index"
                    class="loading-text"
                    :style="{ animationDelay: `${index * 1600}ms` }"
                  >
                    {{ text }}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="credentials-footer">
            <div
              style="
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                gap: 5px;
              "
            >
              <span v-if="errorOnVerification">
                Something went wrong. Check that your credentials are correct
                and try again.
              </span>
              <p
                v-for="error in errors"
                style="
                  padding: 15px;
                  background-color: rgb(255, 223, 223);
                  border-radius: 8px;
                "
              >
                {{ error }}
              </p>
            </div>

            <template v-if="verified">
              <div style="position: relative">
                <SzButton
                  :start-image="require('@/assets/entityIcons/close.svg')"
                  customStyle="
                      display: flex;
                      padding: 7px;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      border-radius: 9999px;
                      background: #FFF;
                    "
                  :clickFunc="changeModalState"
                  @mouseover.native="hoveredDiscard = true"
                  @mouseout.native="hoveredDiscard = false"
                />
                <HoverBadge
                  v-show="hoveredDiscard"
                  innerText="Reset verification process"
                />
              </div>
              <div style="position: relative">
                <SzButton
                  innerText="Verified"
                  :endImage="require('@/assets/entityIcons/checkmark.svg')"
                  customStyle="
                    background: var(--green-tint1, #CFFFA6);
                    color: var(--main, #092E3F);
                    align-items: flex-start;
                  "
                  @mouseover.native="hoveredVerifiedSuccess = true"
                  @mouseout.native="hoveredVerifiedSuccess = false"
                />
                <HoverBadge
                  v-if="verified"
                  v-show="hoveredVerifiedSuccess"
                  style="bottom: 40px"
                  :innerText="'Your Microsoft Sentinel credentials have been successfully verified'"
                />
              </div>
            </template>

            <div v-else style="position: relative">
              <SzButton
                innerText="Verify"
                :clickFunc="verify"
                :isDisabled="!checkFields()"
                @mouseover.native="hoveredTryVerifying = true"
                @mouseout.native="hoveredTryVerifying = false"
              />
              <HoverBadge
                v-show="hoveredTryVerifying"
                style="bottom: 40px"
                :innerText="
                  checkFields()
                    ? 'Verify your Microsoft Sentinel Credentials'
                    : 'Please enter all info to verify your Microsoft Sentinel Credentials'
                "
              />
            </div>
          </div>
        </div>
        <div class="additional-information">
          <div class="header">
            <div class="title">Additional Information</div>
          </div>
          <div class="input-fields">
            <SzInputField
              label="Initial User"
              :id="'initialUser'"
              :tooltipText="'This email will be able to log in after the initial setup with Admin privileges. This can be changed later.'"
              placeholder="Initial user email"
              icon=""
              button-text="Change"
              :value="firstUserEmail"
              :updateInternalValue="updateFirstUserEmail"
              :buttonFunc="allowChangeEmail"
              :isDisabled="!changeEmailPermission"
            />
            <DomainInput :value="domain" :onChange="updateDomain" />
            <SzDropdown
              :value="industry"
              label="Industry"
              title="Select the most fitting"
              icon=""
              :onChange="(event) => updateIndustry(event.target.value)"
            />
          </div>
          <div class="header">
            <div class="title">Connect to Microsoft Security Services:</div>
          </div>
          <div class="input-fields">
            <div>
              <input type="checkbox" v-model="usesSentinel" />
              <span class="checkbox-label">Sentinel</span>
            </div>
            <div style="position: relative; display: none">
              <div
                @mouseover="hoverOverDefender = true"
                @mouseout="hoverOverDefender = false"
              >
                <input disabled type="checkbox" v-model="usesMs365defender" />
                <span class="checkbox-label">MS 365 Defender</span>
              </div>
              <HoverBadge v-show="hoverOverDefender" innerText="Coming soon" />
            </div>
            <div v-if="!usesSentinel">
              At least one Security Service must be connected.
            </div>
          </div>
          <div class="confirm-container">
            <span style="align-self: center" v-if="errorOnConfirm">
              Something went wrong. Check your credentials and try again.
            </span>
            <div style="position: relative">
              <SzButton
                innerText="Confirm"
                customStyle="
                    padding: 10px 15px;
                  "
                :clickFunc="() => toggleCredentialsModal()"
                :isDisabled="!verified || confirmIsLoading || !usesSentinel"
                @mouseover.native="hoveredConfirm = true"
                @mouseout.native="hoveredConfirm = false"
              />
              <HoverBadge
                v-if="!verified"
                v-show="hoveredConfirm"
                style="bottom: 40px"
                innerText="Verify your Sentinel Credentials to continue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      :show="showModal"
      :text="'Would you like to reset the verification process?'"
      :changeModalState="changeModalState"
      :approveFunc="discardVerification"
    />
    <ConfirmModal
      :text="'Would you like to send the following credentials?'"
      :show="showConfirmSendingCredentialsModal"
      :changeModalState="toggleCredentialsModal"
      :approveFunc="confirm"
    >
      <template #modal-body>
        <span style="text-align: left; color: #092e3f">
          <h3><b>Sentinel Information</b></h3>
          <br />
          <p>
            <b>Tenant ID: </b>
            {{ tenantId }}
          </p>
          <p><b>Client Secret: </b> {{ clientSecret }}</p>
          <p><b>Subscription ID: </b>{{ subscriptionId }}</p>
          <p><b>Workspace ID: </b>{{ workspaceId }}</p>
          <p><b>Workspace Name: </b>{{ workspaceName }}</p>
          <br />
          <h3><b>Additional Information</b></h3>
          <br />
          <p><b>Initial User: </b>{{ firstUserEmail }}</p>
          <p><b>Domain Name: </b>{{ domain }}</p>
          <p><b>Industry: </b>{{ industry }}</p>
          <br />
          <h3><b>Connected Services</b></h3>
          <br />
          <p v-if="usesMs365defender">MS 365 Defender</p>
          <p v-if="usesSentinel">Sentinel</p>
        </span>
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import SzButton from "@/components/universal/SzButton/SzButton";
import SzInputField from "@/components/universal/SzInputField/SzInputField";
import SzDropdown from "@/components/universal/SzDropdown/SzDropdown";
import "@/index.css";
import DomainInput from "@/components/DomainInput";
import HoverBadge from "@/components/universal/HoverBadge/HoverBadge";
import ConfirmModal from "@/components/universal/ConfirmModal/ConfirmModal";

import { verifyCredentials } from "../api/verifyCredentials";
import { confirmCredentials } from "../api/confirmCredentials";

export default {
  name: "SentinelCredentialsForm",
  props: ["confirmForm"],
  components: {
    DomainInput,
    SzButton,
    SzInputField,
    SzDropdown,
    HoverBadge,
    ConfirmModal,
  },
  data() {
    return {
      loading: false,
      confirmIsLoading: false,
      errorOnVerification: false,
      errorOnConfirm: false,
      hoveredConfirm: false,
      hoveredDiscard: false,
      verified: false,
      changeEmailPermission: false,
      changeTenantIdPermission: false,
      tenantId: null,
      clientId: null,
      clientSecret: null,
      subscriptionId: null,
      workspaceId: null,
      workspaceName: null,
      industry: null,
      showModal: false,
      showConfirmSendingCredentialsModal: false,
      firstUserEmail: null,
      domain: null,
      usesSentinel: true,
      usesMs365defender: false,
      loadingTexts: ["Please wait . . .", "Verifying Credentials . . ."],
      hoveredVerifiedSuccess: false,
      hoveredTryVerifying: false,
      hoverOverDefender: false,
      errors: [],
    };
  },
  methods: {
    async verify() {
      try {
        this.errorOnVerification = false;
        this.loading = true;
        let result = await verifyCredentials({
          tenant_id: this.tenantId,
          workspace_name: this.workspaceName,
          workspace_id: this.workspaceId,
          subscription_id: this.subscriptionId,
          client_secret: this.clientSecret,
          client_id: this.clientId,
        });

        if (result.errors.length == 0) {
          this.verified = true;
          this.errors = [];
        } else {
          this.errorOnVerification = true;
          this.errors = result.errors;
        }
      } catch (err) {
        this.errorOnVerification = true;
      } finally {
        this.loading = false;
      }
    },
    async confirm() {
      try {
        this.errorOnConfirm = false;
        this.confirmIsLoading = true;

        const credentials = {
          tenant_id: this.tenantId,
          workspace_name: this.workspaceName,
          workspace_id: this.workspaceId,
          subscription_id: this.subscriptionId,
          client_secret: this.clientSecret,
          client_id: this.clientId,
          industry: this.industry,
          domain_name: this.domain,
          first_user: this.firstUserEmail,
          uses_sentinel: this.usesSentinel,
          uses_ms365defender: this.usesMs365defender,
        };
        let result = await confirmCredentials(credentials);

        this.confirmForm(credentials);
      } catch (err) {
        this.errorOnConfirm = true;
      } finally {
        this.confirmIsLoading = false;
      }
    },
    toggleCredentialsModal() {
      this.showConfirmSendingCredentialsModal =
        !this.showConfirmSendingCredentialsModal;
    },
    checkFieldsContent() {
      if (!this.checkFields()) {
        this.verified = false;
      }
    },
    discardVerification() {
      this.verified = false;
    },
    allowChangeEmail() {
      this.changeEmailPermission = !this.changeEmailPermission;
    },
    allowChangeTenantId() {
      this.changeTenantIdPermission = !this.changeTenantIdPermission;
    },
    isNullOrWhitespace(field) {
      return field === null || field.trim() === "";
    },
    checkFields() {
      return [
        this.tenantId,
        this.clientId,
        this.clientSecret,
        this.subscriptionId,
        this.workspaceId,
        this.workspaceName,
      ].every((field) => !this.isNullOrWhitespace(field));
    },
    updateIndustry(newValue) {
      this.industry = newValue;
    },
    updateDomain(newValue) {
      this.domain = newValue;
    },
    updateFirstUserEmail(newValue) {
      this.firstUserEmail = newValue;
    },
    updateTenantId(newValue) {
      this.tenantId = newValue;
    },
    updateClientId(newValue) {
      this.clientId = newValue;
    },
    updateClientSecret(newValue) {
      this.clientSecret = newValue;
    },
    updateSubscriptionId(newValue) {
      this.subscriptionId = newValue;
    },
    updateWorkspaceId(newValue) {
      this.workspaceId = newValue;
    },
    updateWorkspaceName(newValue) {
      this.workspaceName = newValue;
    },
    changeModalState() {
      this.showModal = !this.showModal;
    },
  },
  mounted() {
    window.addEventListener("notifyOfUser", (event) => {
      this.updateTenantId(event.detail.info.tenantId);
      this.updateFirstUserEmail(event.detail.info.username);
    });
  },
  watch: {
    tenantId: "checkFieldsContent",
    clientId: "checkFieldsContent",
    clientSecret: "checkFieldsContent",
    subscriptionId: "checkFieldsContent",
    workspaceId: "checkFieldsContent",
    workspaceName: "checkFieldsContent",
  },
};
</script>

<style scoped>
.checkbox-label {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
}
.container {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: var(--zero, 0px);
}
.workframe {
  display: flex;
  padding: 131px 233px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-radius: var(--zero, 0px);
  background: var(--main, #092e3f);
}
.frame {
  display: flex;
  width: 90%;
  max-width: 862px;
  min-width: 460px;
  padding: var(--xl-spacing, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xxl-spacing, 72px);
  flex-shrink: 0;

  border-radius: var(--def-spacing, 8px);
  background: var(--background, #fff);
}
.header {
  display: flex;
  padding: var(--zero, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;

  border-radius: var(--zero, 0px);
}
.input-credentials {
  display: flex;
  padding: var(--zero, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xl-spacing, 32px);
  align-self: stretch;

  height: 100%;
  width: 100%;
  border-radius: var(--zero, 0px);
}
.sentinel-credentials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--l-spacing, 16px);
  align-self: stretch;

  /*border: 2px solid greenyellow;*/
}
.additional-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--l-spacing, 16px);
  align-self: stretch;
}
.input-fields {
  display: flex;
  position: relative;
  padding: var(--xl-spacing, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--l-spacing, 16px);
  align-self: stretch;

  border-radius: var(--def-round, 4px);
  background: var(--background, #fff);

  /* shadow */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.credentials-footer {
  display: flex;
  padding: var(--zero, 0px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--def-spacing, 8px);
  align-self: stretch;

  border-radius: var(--zero, 0px);
}
.loading-blocker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: calc(100% - var(--xl-spacing, 32px) - var(--xl-spacing, 32px));
  height: calc(100% - var(--xl-spacing, 32px) - var(--xl-spacing, 32px));

  /*right: 27.5px;*/
  /*top: 38.5px;*/
  position: absolute;

  border-radius: var(--zero, 0px);
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);
}
.text-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-text {
  opacity: 0;
  animation: dissolve 3200ms ease-out infinite;
  animation-fill-mode: forwards;
  position: absolute;

  display: flex;
  width: 344px;
  height: 60px;
  margin-top: 21.5px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  color: var(--accent-1, #2a96a8);
  text-align: center;
  leading-trim: both;
  text-edge: cap;

  /* Title Large Black */
  font-family: "Lato", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 28px; /* 127.273% */
  text-transform: uppercase;
}
@keyframes dissolve {
  from {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
.discard-icon-container {
  display: flex;
  padding: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.discard-icon {
  display: flex;
  padding: 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 9999px;
  background: #fff;

  width: 26px;
  height: 26px;
  gap: 10px;

  border: 2px solid red;
}
.confirm-container {
  display: flex;
  padding: var(--zero, 0px);
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--def-spacing, 8px);
  align-self: stretch;

  border-radius: var(--zero, 0px);
}
#logo-container {
  position: relative;
}
@keyframes spin-to-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin-to-left {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#inner-logo {
  position: absolute;
  top: 0;
  left: 0;
  animation: spin-to-left 2s linear infinite;
  width: 205px;
  height: 205px;
}
#outer-logo {
  width: 205px;
  height: 205px;
  animation: spin-to-right 2s linear infinite;
}
</style>
