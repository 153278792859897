export async function verifyCredentials(credentials) {
  const accessToken = localStorage.getItem("accessToken");

  try {
    let response = await fetch(`${base_url}verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(credentials),
    });

    let data = await response.json();

    if (response.status === 200) {
      console.log("data: ", data);

      return data;
    } else {
      throw new Error("Error when verifying credentials.");
    }
  } catch (err) {
    throw new Error("Error when verifying credentials.");
  }
}
