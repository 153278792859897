<template>
  <div class="domain-container">
    <div class="domain-title">Choose a Domain Name</div>
    <div class="domain-input-container">
      <input
        type="text"
        class="domain-input"
        placeholder="Example: yourdomain"
        :value="value"
        @change="(event) => onChange(event.target.value)"
      />
      <div class="domain-postfix">.seculyze.com/app/</div>
      <div class="image-container">
        <b-button id="domainInput" style="background-color: initial; border: 0">
          <img
            class="icon"
            :src="require('@/assets/entityIcons/questionMark.svg')"
            alt="Question Mark Icon"
          />
        </b-button>
        <b-popover target="domainInput" triggers="hover" placement="top">
          <div
            style="
              background: var(--lightest-grey, #f6f6f6);
              border-radius: 8px;
              padding: 15px;
              max-width: 300px;
              color: var(--main, #092e3f);
              font-family: 'Lato', sans-serif;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            "
          >
            <p>
              This URL will be your point of access to the Seculyze application.
            </p>
          </div>
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DomainInput",
  props: ["value", "onChange"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.domain-container {
  display: flex;
  padding: var(--zero, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--def-spacing, 8px);
  align-self: stretch;

  border-radius: var(--zero, 0px);
  font-family: "Lato", sans-serif;
}
.domain-title {
  display: flex;
  height: 11px;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;

  color: var(--main, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Large */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.domain-input-container {
  display: flex;
  height: 56px;
  padding: var(--zero, 0px);
  align-items: center;
  gap: var(--def-spacing, 8px);
  align-self: stretch;

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
}
.domain-input {
  display: flex;
  width: 100%;
  max-width: 261px;
  min-width: 100px;
  height: 56px;
  padding: var(--l-spacing, 16px) var(--def-spacing, 8px);
  align-items: center;
  gap: var(--def-spacing, 8px);

  border-radius: var(--def-round, 4px) var(--def-round, 4px) var(--zero, 0px)
    var(--zero, 0px);
  border-top: var(--zero, 0px) solid var(--main, #092e3f);
  border-right: var(--zero, 0px) solid var(--main, #092e3f);
  border-bottom: 1px solid var(--main, #092e3f);
  border-left: var(--zero, 0px) solid var(--main, #092e3f);
  background: var(--lightest-grey, #f6f6f6);

  color: var(--muted-text, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.domain-input::placeholder,
.domain-input-correct::placeholder,
.domain-input-disabled::placeholder {
  color: var(--normal-black, #898989);
}

.domain-postfix {
  display: flex;
  flex-grow: 1;
  max-width: 150px;
  min-width: 100px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: var(--zero, 0px);

  flex-shrink: 0;

  color: var(--main, #092e3f);
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.image-container {
  width: 100%;
}
.domain-icon {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  flex-shrink: 0;
}
</style>
