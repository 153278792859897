<template>
  <button :disabled="isDisabled" @click="() => {clickFunc()}"
          :class="isDisabled ? 'button-container-disabled' : 'button-container'"
          :style="customStyle"
  >
    <img v-if="startImage" class="icon" :src="startImage" alt="startImage" />
      {{ innerText }}
    <img v-if="endImage" class="icon" :src="endImage" alt="endImage" />
  </button>
</template>

<script>
export default {
  name: "SzButton",
  props: ['innerText', 'startImage', 'endImage', 'customStyle', 'clickFunc', 'isDisabled']
}
</script>

<style scoped>

.button-container {
  display: flex;
  padding: 10px 12px 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 0;
  background: var(--main, #092E3F);

  color: var(--background, #FFF);

  /* Label Black */
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
.button-container-disabled {
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  border-radius: 4px;
  background: var(--light-grey, #D6D6D6);

  color: var(--muted-text, #979394);
  text-align: center;

  /* Label Black */
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
</style>