<template>
  <div id="app">
    <SentinelCredentialsForm v-if="isForm" :confirmForm="confirmForm" />
    <ReceiptPage v-else :credentials="credentials" />
  </div>
</template>

<script>
import SentinelCredentialsForm from "./components/SentinelCredentialsForm.vue";
import ReceiptPage from "@/components/ReceiptPage";
import "./index.css";

export default {
  name: "App",
  components: {
    SentinelCredentialsForm,
    ReceiptPage,
  },
  data() {
    return {
      isForm: true,
      credentials: null,
    };
  },
  methods: {
    confirmForm(credentials) {
      this.credentials = credentials;
      this.isForm = false;
    },
  },
  mounted() {
    window.addEventListener("notifyOfSubscriptionStatus", (event) => {
      if (event.detail.info.credentials_exist) {
        this.credentials = event.detail.info.credentials_exist;
        this.isForm = false;
      }
    });
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap");
</style>
