<template>
  <div class="modal-container" v-if="show" @click.self="changeModalState">
    <div class="modal-content">
      <header class="modal-header" id="modalTitle">
        <SzButton
          :start-image="require('@/assets/entityIcons/close.svg')"
          customStyle="
            display: flex;
            padding: 7px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 9999px;
            background: #FFF;
            float: right;
          "
          :clickFunc="changeModalState"
        />
      </header>

      <section class="modal-body" id="modalDescription">
        <slot name="body">
          <p style="text-align: left">{{ text }}</p>
          <slot name="modal-body"></slot>
          <div class="action-buttons">
            <SzButton
              innerText="Cancel"
              :clickFunc="changeModalState"
              customStyle="
                width: fit-content;
              "
            />
            <SzButton
              innerText="Yes"
              :clickFunc="confirmFunc"
              customStyle="
                background: var(--green-tint1, #CFFFA6);
                color: var(--main, #092E3F);
                width: fit-content;
              "
            />
          </div>
        </slot>
      </section>

      <footer class="modal-footer">
        <slot name="footer"> </slot>
      </footer>
    </div>
  </div>
</template>

<script>
import SzButton from "@/components/universal/SzButton/SzButton";

export default {
  name: "ConfirmModal",
  props: ["show", "changeModalState", "approveFunc", "text"],
  components: {
    SzButton,
  },
  methods: {
    confirmFunc() {
      this.approveFunc();
      this.changeModalState();
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Lato", sans-serif;
}
.modal-content {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 4px;
  min-width: 200px;
}
.modal-header {
  padding: 4px;
  display: flex;
}
.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: var(--normal-black, #050505);
  justify-content: space-between;
  justify-content: end;
}
/*.modal-footer {*/
/*  display: flex;*/
/*  justify-content: flex-end;*/
/*  width: 100%;*/
/*  border-top: 1px solid #eeeeee;*/
/*}*/
.modal-body {
  position: relative;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-direction: column;
  text-align: center;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
