<template>
  <div class="badge-container">
    {{ innerText }}
  </div>
</template>

<script>
export default {
  name: "HoverBadge",
  props: ["innerText"],
};
</script>

<style scoped>
.badge-container {
  z-index: 100;
  position: absolute;
  bottom: 15px;
  left: 20px;
  height: 42px;
  width: max-content;

  display: inline-flex;
  padding: var(--l-spacing, 16px);
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--l-spacing, 16px);
  background: var(--lightest-grey, #f6f6f6);

  color: var(--main, #092e3f);
  text-align: center;
  leading-trim: both;
  text-edge: cap;

  /* Body Small */
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
