<template>
  <div class="container">
    <div class="message-container">
      <div class="messages">
        <div class="title">SUCCESS</div>
        <div class="subtitle">
          Your Seculyze subscription has been successfully integrated with your
          Microsoft Sentinel Workspace.
        </div>
        <div class="subtitle">
          We are now setting up a customized environment for your organization,
          <b>{{ credentials.domain_name }}</b
          >. This process may take about an hour to complete.
        </div>
        <div class="subtitle">
          A detailed email has been sent to
          <b>{{ credentials.first_user }}</b> with your subscription
          information. You will receive a follow-up notification once your
          environment is fully operational.
        </div>
        <div class="subtitle">Stay tuned, and welcome aboard!</div>
        <SzButton
          innerText="Check Progress"
          custom-style="
            padding: 10px 8px;
            gap: 10px;
            background: var(--background, #FFF);
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
            color: var(--Prussian-Blue, #082E3F);
          "
        />
      </div>
    </div>
    <div class="support-container">
      <div class="support-button">
        <div class="common-text">Any questions?</div>
        <SzButton
          innerText="Contact support"
          custom-style="
            padding: 10px 8px;
            gap: 10px;
            background: var(--background, #FFF);
            color: var(--Prussian-Blue, #082E3F);
            border-radius: 4px;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
          "
        />
      </div>
      <div class="support-logo">
        <img
          class="support-logo"
          :src="require('@/assets/entityIcons/logo.svg')"
          alt="logo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "@/index.css";
import SzButton from "@/components/universal/SzButton/SzButton";

export default {
  name: "ReceiptPage",
  props: ["credentials"],
  components: {
    SzButton,
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 100vh;

  padding: 131px 233px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: var(--zero, 0px);
}
.message-container {
  display: flex;
  width: 862px;
  padding: var(--xl-spacing, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--xxl-spacing, 72px);

  border-radius: var(--def-spacing, 8px);
  background: var(--background, #fff);
}
.messages {
  display: flex;
  padding: var(--zero, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;

  border-radius: var(--zero, 0px);
}
.support-container {
  display: flex;
  width: 862px;
  padding: var(--xl-spacing, 32px);
  justify-content: flex-end;
  align-items: center;
  gap: var(--xl-spacing, 32px);

  border-radius: var(--def-spacing, 8px);
}
.support-button {
  display: flex;
  padding: var(--zero, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;

  border-radius: var(--zero, 0px);
}
.common-text {
  align-self: stretch;

  color: var(--background, #fff);
  text-align: right;
  leading-trim: both;
  text-edge: cap;

  /* Body Large */
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.support-logo {
  display: flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: var(--zero, 0px);
  transform: scaleX(-1);
}
</style>
